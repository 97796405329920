import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { FaEnvelopeOpen, FaGithub, FaHome, FaJava, FaLinkedin, FaPhone, FaPhp, FaPython, FaJs, FaStackOverflow } from 'react-icons/fa';
import { SiAmazonaws, SiAngular, SiDjango, SiDocker, SiGooglecloud, SiIntellijidea, SiVisualstudiocode, SiKubernetes, 
  SiMicrosoftazure, SiMysql, SiPostgresql, SiReact, SiSpring, SiUbuntu, SiGit, SiJenkins, SiLaravel } from "react-icons/si";


const Sidebar = () => {

  return (
    <div
      id="sidebar"
      className="w-full sm:max-w-sm p-8 bg-gradient-to-b from-jose-blue-aqua to-jose-red-light via-jose-blue-aqua text-jose-blue-dark"
    >
      <div className="px-2 mb-12">
        <StaticImage
          src="../images/jose-profile-bw.png"
          placeholder="blurred"
          alt="Profile Picture"
          className="rounded-full mx-auto mb-2"
        />
        <h1 className="text-center text-3xl font-semibold mb-2">Jose Rosas Bogdanovich</h1>
        <h2 className="text-center text-xl font-light">Fullstack Developer</h2>
      </div>

      <div className="font-light text-lg px-2 mb-12">
        <h2 className="text-xl font-semibold mb-4">Contact</h2>
        <div className="flex items-center my-3">
          <span className="inline w-6 mr-4"><FaEnvelopeOpen /></span>
          <a href="mailto:jsrosas@gmail.com">jsrosas@gmail.com</a>
        </div>
        <div className="flex items-center my-3">
          <span className="inline w-6 mr-4"><FaPhone /></span>
          <a href="tel:">+31 6 15624592</a>
        </div>
        <div className="flex items-center my-3">
          <span className="inline w-6 mr-4"><FaHome /></span>
          <div>
            <p>Boslaan 163</p>
            <p>Den haag, 2594ND</p>
          </div>
        </div>
      </div>
      <div className="font-light text-lg px-2 mb-12">
        <h2 className="text-xl font-semibold mb-4">On the Web</h2>
        <div className="flex items-center my-3">
          <span className="inline w-6 mr-4"><FaGithub /></span>
          <a href="https://github.com/jsrosas" target="_blank" rel="noopener noreferrer">GitHub</a>
        </div>
        <div className="flex items-center my-3">
          <span className="inline w-6 mr-4"><FaStackOverflow /></span>
          <a href="https://stackoverflow.com/users/4322670/jsrosas?tab=profile" target="_blank" rel="noopener noreferrer"
          >StackOverflow</a
          >
        </div>
        <div className="flex items-center my-3">
          <span className="inline w-6 mr-4"><FaLinkedin /></span>
          <a href="https://www.linkedin.com/in/jsrosas/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        </div>
      </div>

      <div className="font-light text-lg px-2 mb-12">
        <h2 className="text-xl font-semibold mb-4">Skills & Tools</h2>
        <ul className="list-none">
          <li>
            <label htmlFor="java-skill" className="flex"><FaJava size={"1.5em"} className="inline mr-3"/>
              <h3 className="text-2xl">Java</h3>
            </label>
            <progress id="java-skill" max="100" value="90"></progress>
          </li>
          <li>
            <label htmlFor="php-skill"className="flex"><FaPhp size={"1.5em"} className="inline mr-3" />
            <h3 className="text-2xl">PHP</h3> 
            </label>
            <progress id="php-skill" max="100" value="90"></progress>
          </li>
          <li>
            <label htmlFor="python-skill"className="flex"><FaPython size={"1.5em"} className="inline mr-3"/><h3 className="text-2xl">Python</h3> </label>
            <progress id="python-skill" max="100" value="90"></progress>
          </li>
          <li>
            <label htmlFor="js-skill"className="flex"><FaJs size={"1.5em"} className="inline mr-3"/><h3 className="text-2xl">JavaScript</h3> </label>
            <progress id="js-skill" max="100" value="75"></progress>
          </li>
          <li>
            <label htmlFor="spring-skill"className="flex"><SiSpring size={"1.5em"} className="inline mr-3"/><h3 className="text-2xl">Spring MVC</h3></label>
            <progress id="spring-skill" max="100" value="65"></progress>
          </li>
          <li>
            <label htmlFor="django-skill"className="flex"><SiDjango size={"1.5em"} className="inline mr-3"/><h3 className="text-2xl">Django</h3> </label>
            <progress id="django-skill" max="100" value="90"></progress>
          </li>
          <li>
            <label htmlFor="django-skill"className="flex"><SiLaravel size={"1.5em"} className="inline mr-3"/><h3 className="text-2xl">Laravel</h3> </label>
            <progress id="django-skill" max="100" value="90"></progress>
          </li>
          <li>
            <label htmlFor="mysql-skill"className="flex"><SiMysql size={"1.5em"} className="inline mr-3"/><h3 className="text-2xl">MySQL</h3> </label>
            <progress id="mysql-skill" max="100" value="75"></progress>
          </li>
          <li>
            <label htmlFor="postgresql-skill"className="flex"><SiPostgresql size={"1.5em"} className="inline mr-3"/><h3 className="text-2xl">PostgreSQL</h3> </label>
            <progress id="postgresql-skill" max="100" value="75"></progress>
          </li>
          <li>
            <label htmlFor="react-skill"className="flex"><SiReact size={"1.5em"} className="inline mr-3"/><h3 className="text-2xl">React</h3> </label>
            <progress id="react-skill" max="100" value="65"></progress>
          </li>
          <li>
            <label htmlFor="angular-skill"className="flex"><SiAngular size={"1.5em"} className="inline mr-3"/><h3 className="text-2xl">Angular</h3> </label>
            <progress id="angular-skill" max="100" value="65"></progress>
          </li>
          <li>
            <div><h2 className="text-xl font-semibold mb-4">Infrastructure / Hosting / DB</h2></div>
            <p className="leading-8">
              <span className="rounded-xl bg-gradient-to-b from-jose-red-light to-jose-red-dark px-3">AWS <SiAmazonaws size={"1em"} className="inline"/></span>
              <span className="rounded-xl bg-gradient-to-b from-jose-red-light to-jose-red-dark px-3">Azure <SiMicrosoftazure size={"1em"} className="inline"/></span>
              <span className="rounded-xl bg-gradient-to-b from-jose-red-light to-jose-red-dark px-3">Jenkins <SiJenkins size={"1em"} className="inline"/></span>
            </p>
            <p className="leading-8">
              <span className="rounded-xl bg-gradient-to-b from-jose-red-light to-jose-red-dark px-3">GoogleCloud <SiGooglecloud size={"1em"} className="inline"/></span>
              <span className="rounded-xl bg-gradient-to-b from-jose-red-light to-jose-red-dark px-3">Ubuntu <SiUbuntu size={"1em"} className="inline"/></span>
              <span className="rounded-xl bg-gradient-to-b from-jose-red-light to-jose-red-dark px-3">Docker <SiDocker size={"1em"} className="inline"/></span>
              <span className="rounded-xl bg-gradient-to-b from-jose-red-light to-jose-red-dark px-3">Kubernetes <SiKubernetes size={"1em"} className="inline"/></span>
            </p>
          </li>
          <li>
            <div><h2 className="text-xl font-semibold mb-4">Tools & IDE's</h2></div>
            <p className="leading-8">
              <span className="rounded-xl bg-gradient-to-b from-jose-red-light to-jose-red-dark px-3">Intellij <SiIntellijidea size={"1em"} className="inline"/></span>
              <span className="rounded-xl bg-gradient-to-b from-jose-red-light to-jose-red-dark px-3">Pycharm <SiIntellijidea size={"1em"} className="inline"/></span>
              <span className="rounded-xl bg-gradient-to-b from-jose-red-light to-jose-red-dark px-3">VSC <SiVisualstudiocode size={"1em"} className="inline"/></span>
            </p>
            <p className="leading-8">
              <span className="rounded-xl bg-gradient-to-b from-jose-red-light to-jose-red-dark px-3">Git <SiGit size={"1em"} className="inline"/></span>
            </p>
          </li>
        </ul>
      </div>

      <div className="font-light text-lg px-2 mb-12">
        <h2 className="text-xl font-semibold mb-4">Education</h2>
        <h3 className="font-semibold">BSc Computer Science</h3>
        <p>2001 - 2005 The University of Manchester</p>
      </div>

      <div className="font-light text-lg px-2 mb-12">
        <h2 className="text-xl font-semibold mb-4">Languages</h2>
        <h3 className="font-semibold">English</h3>
        <p>Native</p>
        <h3 className="font-semibold">Spanish</h3>
        <p>Native</p>
        <h3 className="font-semibold">Dutch</h3>
        <p>Entry Level</p>
      </div>
    </div>
  )
}
export default Sidebar