import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Sidebar from "./sidebar"

const Layout = ({ children }) => {
  return (
    <>
      <div className="container mx-auto max-w-screen-xl">
        <main
          id="wrapper"
          className="flex flex-col sm:flex-row-reverse sm:m-12 shadow-2xl bg-jose-blue-light"
        >
          <Sidebar />
          <div className="content w-full p-12 lg:p-12 md:p-8 sm:p-3">
            <ul className="flex">
              <li className="flex-1 mr-2">
                <Link to="/" className="text-center block border border-jose-red-light py-2 px-6 bg-jose-red-light hover:bg-jose-red-dark text-jose-blue-dark" activeClassName="active:bg-jose-red-dark"><h1>Profile</h1></Link>
              </li>
              <li className="flex-1 mr-2">
                <Link to="/experience" className="text-center block border border-jose-red-light py-2 px-6 bg-jose-red-light hover:bg-jose-red-dark text-jose-blue-dark" activeClassName="active:bg-jose-red-dark"><h1>Experience</h1></Link>
              </li>
            </ul>
            {children}
          </div>
        </main>
      </div>
      <footer
        className="mt-12 text-center flex justify-center flex-col md:flex-row mx-12"
      >
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
